<template>
  <div>
    <div class="conversationsPermalink" v-if="renderComponent">
      <conversation-list
        :conversations="permalinkConversation"
        v-if="isMessage && renderComponent"
        :isPermalink="isPermalink"
        :isMessage="isMessage"
        v-model="panel"
        class="mt-6"
      ></conversation-list>
      <conversation-list
        :conversations="permalinkConversation"
        v-if="!isMessage && renderComponent"
        :isPermalink="isPermalink"
        :isMessage="isMessage"
        v-model="panel"
        class="mt-6"
      ></conversation-list>
    </div>

    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
      :force-use-infinite-wrapper="true"
      v-if="!isMessage"
    >
      <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
      <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
    </infinite-loading>
    <v-fade-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        outlined
        color="white"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import store from "../store/index";

import ConversationList from "@/components/ConversationList.vue";
import InfiniteLoading from "vue-infinite-loading";
export default {
  name: "DarkReconConversations",
  components: {
    ConversationList,
    InfiniteLoading,
  },
  data: () => ({
    panel: [0],
    isMessage: false,
    isPermalink: true,
    items: [],
    infiniteId: 1,
    fab: false,
    page: 1,
    renderComponent: false,
  }),
  created() {
    if (this.$route.params.messageId) {
      this.isMessage = true;
      store.dispatch("darkRecon/addPermalinkConversation", [
        this.$route.params.conversationId,
        this.$route.params.messageId,
      ]);
    } else {
      store.dispatch("darkRecon/addPermalinkConversation", [
        this.$route.params.conversationId,
      ]);
    }
  },
  mounted() {
    setTimeout(() => {
      this.forceRerender();
    }, 1000);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      this.page += 1;
      store
        .dispatch("darkRecon/loadConversationPermalinkMessages", [
          this.$route.params.conversationId,
          this.page,
        ])
        .then((result) => {
          this.forceRerender();
          if (result === null) {
            this.page = 1;
            $state.loaded();
            return;
          }
          if (result.length > 0) {
            $state.loaded();
            return;
          }
          if (result.length === 0) {
            this.page = 1;
            $state.complete();
            return;
          }
        });
    },
  },
  computed: {
    permalinkConversation() {
      return store.state.darkRecon.permalinkConversation || {};
    },
    hasMessages() {
      const convo = store.state.darkRecon.permalinkConversation[0] || [];
      return convo.messages;
    },
  },
};
</script>
<style scoped>
.conversationsPermalink >>> .v-icon.v-icon.v-icon--link:nth-child(1) {
  display: none;
}
</style>
